#version 300 es

precision mediump float;

uniform float uTime;
#define t uTime
uniform vec2 uResolution;

out vec4 fragColor;

mat2 m(float a){float c=cos(a), s=sin(a);return mat2(c,-s,s,c);}

float map(vec3 p){
    p.xz*= m(t*0.4);p.xy*= m(t*0.3);
    vec3 q = p*2.+t;
    return length(p+vec3(sin(t*0.7)))*log(length(p)+1.) + sin(q.x+sin(q.z+sin(q.y)))*0.5 - 1.;
}

void mainImage( out vec4 fragColor, in vec2 fragCoord ){	
	
  vec2 p = fragCoord.xy/uResolution.y - vec2(.5,.5);
  vec3 cl = vec3(1.);
  float d = .5;

  for(int i=0; i<=5; i++)	{

    vec3 p = vec3(1,1,5.) + normalize(vec3(p, -1.))*d;
    float rz = map(p);
    float f =  clamp((rz - map(p+.1))*0.5, -.1, 1. );
    vec3 l = vec3(.7,0.7,.9) + vec3(5., 2.5, 3.)*f;
    cl = cl*l + smoothstep(2.5, .0, rz)*.7*l;
    d += min(rz, 1.);

	}
  
  fragColor = vec4(cl, 1.);

}

void main(){
  
  mainImage(fragColor, gl_FragCoord.xy);
  
}