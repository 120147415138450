import chain from '@webglify/chain'
import vertexShader from './shaders/vertex.glsl'
import fragmentShader from './shaders/fragment.glsl'

const canvas = document.createElement('canvas')
canvas.setAttribute('id', 'homepage')
const gl = canvas.getContext('webgl2')!

const [canvasWidth, canvasHeight] = [window.innerWidth, window.innerHeight]
document.body.appendChild(canvas)

const {renderFrame} = chain(gl, [{
  vertexShader,
  fragmentShader,
  canvasWidth,
  canvasHeight
}])


const animate = (t) => {
  requestAnimationFrame(animate)
  renderFrame(t * 0.0005)
  
}

animate(0)

